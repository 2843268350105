/** @jsx h */

import { h } from 'preact';
import { useContext, useState } from 'preact/hooks';
import { twMerge } from 'tailwind-merge';

import { switchToSMSThread } from '../../api';

import { ConciergeContext } from './container';

export const MobileCard = () : JSX.Element | null => {
    const [ inputValue, setInputValue ] = useState('');
    const conciergeContext = useContext(ConciergeContext);

    if (!conciergeContext) {
        return null;
    }

    const handleInputChange = (e : Event) : void => {
        const target = e.target as HTMLInputElement;
        setInputValue(target.value);
    };

    const handleMessageClick = () : void => {
        if (inputValue) {
            void switchToSMSThread({
                message: `${ conciergeContext.optinMessage }\n\n${ inputValue }`
            });
        }
    };

    return (
        <div className={ 'p-4 bg-white rounded-lg shadow-lg font-sans w-[335px] h-[214px]' }>
            <h3
                className={
                    twMerge(
                        'font-medium mb-2'
                    )
                }
                style={ { color: conciergeContext.brandPrimaryColor } }>{ 'Concierge Shopper' }
            </h3>

            <p className={
                twMerge(
                    'text-sm mb-4 text-gray-600'
                )
            }>{ 'Our live concierge will shop for your preferences and help you access discounts.' }
            </p>
            <div className={
                twMerge(
                    'border-2 rounded-lg mb-3 transition-all',
                    [ `ring-[${ conciergeContext.brandPrimaryColor }]` ]
                )
            }>
                <input
                    type={ 'text' }
                    value={ inputValue }
                    onInput={ handleInputChange }
                    placeholder={ 'I\'m looking for...' }
                    className={
                        twMerge(
                            'w-full bg-white text-sm text-black p-2.5',
                            'rounded-lg focus:outline-none'
                        )
                    }
                />
            </div>
            <button
                type={ 'button' }
                onClick={ handleMessageClick }
                disabled={ !inputValue }
                style={
                    {
                        backgroundColor: inputValue
                            ? conciergeContext.brandPrimaryColor
                            : `${ conciergeContext.brandPrimaryColor }66`
                    }
                }
                className={
                    twMerge(
                        'w-full py-2.5 px-4 rounded-lg text-sm font-medium transition-colors',
                        inputValue
                            ? `text-white hover:bg-[${ conciergeContext.brandPrimaryColor }]`
                            : `text-white cursor-not-allowed`
                    )
                }
            >
                { 'Message Concierge' }
            </button>
        </div>
    );
};
